<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    
                    <!-- Section Detail Start -->

                    <!--General information start-->
                    <div class="content-onglets table" v-if="key == 'detail_general_information' && item.show">
                        <table class="comparateur2 border-top-gray">
                            <tr v-if="data.smc.submission">
                                <th width="20%" class="text-left bg-white">Submission type</th>
                                <td class="bg-white text-left"> {{ data.smc.submission }} </td>
                            </tr>
                            <tr v-if="data.guidance_id">
                                <th width="20%" class="text-left bg-white">SMC Drug ID</th>
                                <td class="bg-white text-left"> {{ data.guidance_id}} </td>
                            </tr>
                            <tr>
                                <th width="20%" class="text-left bg-white">Approved patient access scheme</th>
                                <td class="bg-white text-left"> {{ data.smc.approved_patient_access_scheme === 1 ? $t('Yes') : $t('No') }} </td>
                            </tr>
                            <tr v-if="data.smc.type_of_patient_access_scheme">
                                <th width="20%" class="text-left bg-white">Type of patient access scheme</th>
                                <td class="bg-white text-left"> {{ data.smc.type_of_patient_access_scheme }} </td>
                            </tr>
                        </table>
                    </div>
                    <!--General information end-->

                    <!-- key_documents -->
                    <KeyDocument v-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                    <!-- eams -->
                     <table  class="comparateur2 border-top-gray" v-else-if="key == 'eams' && item.show" >
                        <tbody>
                            <tr>
                                <th>Granted</th>
                                <th>Expired</th>
                                <th>EAMS number</th>
                            </tr>
                            <tr> 
                                <td>{{data.smc['date_eams_granted'] ? DDMMMYY(data.smc['date_eams_granted']) : "-" }}</td>
                                <td>{{data.smc['date_eams_expired'] ? DDMMMYY(data.smc['date_eams_expired']) : "-"}} </td>
                                <td>                                
                                <template  v-if="data.smc['eams_number']">                                                                       
                                        <LinkUpload v-if="data.smc['eams_pdf']" :href="'/uploads/Smc/'+$route.params.id+'/eams_pdf.pdf'"  target="_blank" rel="noopener">{{data.smc['eams_number']}}</LinkUpload> 
                                        <a v-else-if="data.smc['eams_link']" :href="data.smc['eams_link']"  target="_blank" rel="noopener">{{data.smc['eams_number']}}</a> 
                                        <p v-else>{{data.smc['eams_number']}}</p>                                     
                                </template>
                                    <template v-else>
                                        -
                                    </template>                                
                                </td>
                            </tr>
                            
                            <tr v-if="data.smc['full_indication']">
                                <th colspan="3" align="center">Full indication</th> 
                            </tr>
                            <tr>
                                <td colspan="3">
                                        <span v-html="data.smc['full_indication'] "></span>
                                </td>
                            </tr>         
                        </tbody>                
                    </table>

                    <!--indication start-->
                    <Indication  v-else-if="key == 'detail_indication' && item.show"/>

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->
            
                    <!--MA spec start-->
                    <table class="comparateur2  text-left border-top-gray"  v-else-if="key == 'detail_MaSpac' && item.show" >
                        <tr colspan="3">
                            <td class="bold"><strong style="color:#000;"> {{$t('TRS_AMM')}}</strong></td>                            
                            <td>{{ data.smc['smc_type_amm'] ? $t('ceesp_type_amm_'+data.smc['smc_type_amm']) : '-'}}</td>
                        </tr>
                    </table>
                    <!--MA spec end-->

                    <!-- 'sub_detail/conclusion.ctp' -->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />  

                    <!-- detail_key_conclusion start-->
                    <table class="comparateur2  tb-col2 text-left border-top-gray" v-else-if="key == 'detail_key_conclusion' && item.show" >  
                        <tbody>
                            <tr>
                                <td width="100%">
                                    <div style="width: 100%;display: inline-block;" v-html="data.smc['key_conclusion'] ? data.smc['key_conclusion'] : '-'"></div>                                        
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <!-- detail_key_conclusion end-->

                    <!--SMC_briefing note start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_SMC_briefing_note' && item.show" >  
                        <p v-html="data.smc.note"></p>
                    </div>  
                    <!--SMC_briefing note end-->

                    <!--Background start-->  
                    <div class="content-onglets table" v-else-if="key == 'sum_background' && item.show" > 
                        <p v-html="data.smc['background']"></p>
                    </div>
                    <!--Background end-->

                     <!--Nature of condition start-->  
                    <div class="content-onglets table"  v-else-if="key == 'sum_nature_of_condition' && item.show" > 
                        <p v-html="data.smc['nature_of_condition']"></p>
                    </div>
                        <!--Nature of condition end-->

                    <!--Summary of evidence on comparative efficacy start-->  
                    <div class="content-onglets table"   v-else-if="key == 'Summary_of_evidence_on_comparative_efficacy' && item.show" > 
                        <p v-html="data.smc['sum_ece']"></p>
                    </div>
                    <!--Summary of evidence on comparative efficacy end-->
        
                    <!--@todo EssaisClinique start-->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>

                    <!--Summary of evidence on comparative safety start-->  
                    <div class="content-onglets table" v-else-if="key == 'sum_ecs' && item.show" > 
                        <p v-html="data.smc['sum_ecs']"></p>
                    </div>
                    <!--Summary of evidence on comparative safety end-->

                    <!--Summary of evidence on comparative safety start-->  
                    <div class="content-onglets table"  v-else-if="key == 'sum_cei' && item.show" > 
                        <p v-html="data.smc['sum_cei']"></p>
                    </div>
                    <!--Summary of evidence on comparative safety end-->

                    <!--Summary of comparative health economic evidence start-->          
                    <div class="content-onglets table" v-else-if="key == 'sum_che' && item.show" > 
                        <p v-html="data.smc['sum_che']"></p>
                    </div>    
                    <!--Summary of comparative health economic evidence end-->

                    <!-- @todo clinical_trial -->

                    <!-- 'sub_detail/evaluation_economic.ctp'  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.smc.evaluation_economic" />

                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                    <!--BudgetImpact start-->
                    <BudgetImpact :data="data.smc['budget_impacts']" :smc_add_bi="data.smc.add_bi" v-else-if="key == 'detail_BudgetImpact' && item.show" /> 
                    <!--BudgetImpact end-->

                    <div class="content-onglets table"  v-else-if="key == 'effectiveness_evidences' && item.show" >
                        <!-- {{data.smc}}  -->
                        <table class="comparateur2">
                            <thead>
                                <tr>
                                    <th width="10%" class="first" >Model type</th> 
                                    <th width="10%" >ICER comparator</th>
                                    <th width="10%" >ICER type</th>
                                    <th width="10%" >ICER basecase (GBP)</th>
                                    <th width="10%" >ICER lower (GBP)</th>
                                    <th width="10%" >ICER upper (GBP)</th>
                                    <th width="10%" >ICER probability </th> 
                                    <th width="30%" class="last">SMC comment on ICER</th> 
                                </tr>
                            </thead>
                            <tbody>                                
                                <tr v-for="(items,keys) in data.smc.smc_effectiveness_evidence" :key="'smc_effectiveness_evidence'+keys"> 
                                    <td>{{ items['icer_model_type'] ? items['icer_model_type'] : "-"  }}</td> 
                                    <td>{{ items['icer_comparator'] ? items['icer_comparator'] : "-"  }}</td>
                                    <td>{{ items['icer_type'] ? items['icer_type']: "-"   }}</td>
                                    <td>{{ items['icer_basecase_text'] ? items['icer_basecase_text'] : "-"  }}</td>
                                    <td>{{ items['icer_lower_text'] ? items['icer_lower_text'] : "-"  }}</td>
                                    <td>{{ items['icer_upper_text'] ? items['icer_upper_text'] : "-"  }}</td>
                                    <td>{{ items['icer_probability'] ? items['icer_probability'] : "-"  }}</td>
                                    <td>
                                        <span v-html="items['icer_smc_comment'] ? items['icer_smc_comment'] : '-'"> </span>   
                                    </td>
                                </tr>                                
                            </tbody>
                        </table>                        
                        <div class="space"></div>                        
                        <!-- @todo full analysis -->
                        <a class="btn btn-mini btn-warning" style="color: #fff;" target="_blank" rel="noopener" :href="FULL_BASE_URL + '/pdf/view_pdf_popup/'+ $route.params.id +'/' + $route.params.agency" > Full analysis </a>
                        <div class="space"></div>
                    </div>


                    <!--Summary of ultra orphan decision-making framework start-->
                    <div class="content-onglets" v-else-if="key == 'sum_odm' && item.show" > 
                        <p v-html="data.smc['sum_odm']"></p>
                    </div>
                    <!--Summary of ultra orphan decision-making framework end-->


                    <!--Value of money start--> 
                    <div class="content-onglets table" v-else-if="key == 'sum_value_of_money' && item.show" > 
                        <p v-html="data.smc['value_of_money']"></p>
                    </div>           
                    <!--Value of money end-->

                    <!--Impact beyond direct health benefits and on specialist services start--> 
                    <div class="content-onglets"  v-else-if="key == 'sum_impact_beyond' && item.show" > 
                        <p v-html="data.smc['impact_beyond']"></p>
                    </div>
                    <!--Impact beyond direct health benefits and on specialist services end-->

                    <!--Cost to NHS and Personnal Social Services start-->        
                    <div class="content-onglets"  v-else-if="key == 'sum_cost_nhs' && item.show" > 
                        <p v-html="data.smc['cost_nhs']"></p>
                    </div>
                    <!--Cost to NHS and Personnal Social Services end-->

                    <!--Conclusion start--> 
                    <div class="content-onglets" v-else-if="key == 'sum_conclusion' && item.show" > 
                        <p v-html="data.smc['conclusion']"></p>
                    </div>
                    <!--Conclusion end-->

                    <!--Summary of patient and clinician engagement start-->  
                    <div class="content-onglets" v-else-if="key == 'sum_pce' && item.show" > 
                        <p v-html="data.smc['sum_pce']"></p>
                    </div>
                    <!--Summary of patient and clinician engagement end-->

                    <!--Summary of patient and public involvement start-->              
                    <div class="content-onglets table" v-else-if="key == 'sum_ppi' && item.show" > 
                        <p v-html="data.smc['sum_ppi']"></p>
                    </div>

                    <!--Summary of patient and public involvement end-->

                    <!--Additional information: guidelines and protocols start-->        
                    <div class="content-onglets table" v-else-if="key == 'add_gp' && item.show" > 
                        <p v-html="data.smc['add_gp']"></p>
                    </div>
                    <!--Additional information: guidelines and protocols end-->

                    <!-- Additional information: comparators start -->
                   <div class="content-onglets table" v-else-if="key == 'add_c' && item.show" > 
                        <p v-html="data.smc['add_c']"></p>
                        <template v-if="data.smc.smc_comparator && data.smc.smc_comparator.length > 0">
                            <div class="space"></div>
                            <div class="table-responsive">
                                <table class="comparateur2">
                                    <thead>
                                        <tr>
                                            <th width="75%">{{$t('SMC_comparator')}}</th>
                                            <th width="25%">{{$t('SMC_position')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                        
                                        <tr v-for="(items,keys) in data.smc.smc_comparator" :key="'smc_comparator'+keys">
                                            <td class="">{{items['comparator']}}</td>
                                            <td>{{ items['accepted'] ? items['accepted'] : '-'}} </td>
                                        </tr>    
                                    </tbody>
                                </table>
                                <div class="space"></div>
                            </div>                      
                        </template>
                    </div>
                    <!--Additional information: comparators end-->
 
                    <!--Cost of relevant comparators start-->        
                    <div class="content-onglets "  v-else-if="key == 'crc' && item.show" > 
                        <p v-html="data.smc['crc']"></p>
                    </div>          
                    <!--Cost of relevant comparators end-->

                    <!--@todo Link SMC-->  




                <!-- Section Detail End -->
                </div>
            </template>

            <!-- Link Section Start-->       
           <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LinkUpload from '../LinkUpload.vue'
import LeftSection from '../LeftSection.vue'
import TreatmentLine from '../treatment_line.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import BudgetImpact from '../budget_impact.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import KeyDocument from '../key_document.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'
import { url_prismaccess } from '@/app.config';

export default {
    name:'smc',
    components : {
        LinkUpload,
        LeftSection,
        TreatmentLine,
        EvaluationEconomic,
        EconomicEvaluation,
        BudgetImpact,
        Conclusion,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        Indication
    },
    data() {
       return{
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_general_information : {'title' : 'general_information', 'show' : true, 'enable' : false, 'orange_text':false},
                eams : {'title' : 'Early access to medicines scheme (EAMS)', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_indication : {'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false},
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_MaSpac : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_Conclusion : {'title' :'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_key_conclusion: {'title' : 'Key conclusion', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_SMC_briefing_note :{'title' : 'SMC_briefing', 'show' : true, 'enable' : false, 'orange_text':false},
                sum_background :{'title' : 'SMC_background', 'show' : true, 'enable' : false, 'orange_text':false},
                sum_nature_of_condition :{'title' : 'SMC_nature_of_condition', 'show' : true, 'enable' : false, 'orange_text':false},
                Summary_of_evidence_on_comparative_efficacy  :{'title' : 'SMC_ece', 'show' : true, 'enable' : false, 'orange_text':false},
                sum_ecs :{'title' : 'SMC_ecs', 'show' : true, 'enable' : false, 'orange_text':false},
                sum_cei :{'title' : 'SMC_cei', 'show' : true, 'enable' : false, 'orange_text':false},
                sum_che :{'title' : 'SMC_che', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_clinical_trials :{'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_EvaluationEconomic :{'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_EconomicEvaluation :{'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_BudgetImpact :{'title' : 'Budget impact', 'show' : true, 'enable' : false, 'orange_text':true},
                effectiveness_evidences :{'title' : 'Cost effectiveness Evidence', 'show' : true, 'enable' : false, 'orange_text':false},
                sum_odm :{'title' : 'SMC_odm', 'show' : true, 'enable' : false, 'orange_text':false},
                sum_value_of_money :{'title' : 'SMC_value_of_money', 'show' : true, 'enable' : false, 'orange_text':false},
                sum_impact_beyond :{'title' : 'SMC_impact_beyond', 'show' : true, 'enable' : false, 'orange_text':false},
                sum_cost_nhs :{'title' : 'SMC_cost_nhs', 'show' : true, 'enable' : false, 'orange_text':false},
                sum_conclusion :{'title' : 'SMC_conclusion', 'show' : true, 'enable' : false, 'orange_text':false},
                sum_pce :{'title' : 'SMC_pce', 'show' : true, 'enable' : false, 'orange_text':false},
                sum_ppi :{'title' : 'SMC_ppi', 'show' : true, 'enable' : false, 'orange_text':false},
                add_gp:{'title' : 'SMC_add_gp', 'show' : true, 'enable' : false, 'orange_text':false},
                add_c :{'title' : 'SMC_add_c', 'show' : true, 'enable' : false, 'orange_text':false},
                crc  :{'title' : 'SMC_crc', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_key_documents: {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false},
            },
            
       } 
    },
      created(){
        if(this.data && this.data.smc){       

            if(this.data.smc || this.data.guidance_id){
                this.list_check_box['detail_general_information'].enable = true
            }      
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }
            if (
                this.data.smc['date_eams_granted'] ||
                this.data.smc['date_eams_expired'] ||
                this.data.smc['full_indication'] ||
                this.data.smc['eams_number'] ) 
            {               
                this.list_check_box['eams'].enable = true
            }

            if (this.data['indication_en']) 
            {
                this.list_check_box['detail_indication'].enable = true
            }   

            if ( this.data['treatment_line'] || this.data['specificity'] || this.data['administration'] ) 
            {                
                this.list_check_box['treatment_line'].enable = true
            }

            if (this.data.smc['smc_type_amm']) 
            {  
                this.list_check_box['detail_MaSpac'].enable = true
            }

            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) 
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }

            if (this.data.smc['key_conclusion']) 
            {
                this.list_check_box['detail_key_conclusion'].enable = true
            }
            
            if (this.data.smc['note'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                                                                 
                this.list_check_box['detail_SMC_briefing_note'].enable = true
            }                 
            
            if (this.data.smc['background'].replace(/(<([^>]+)>)/gi, "").trim() ) 
            {                                                                                 
                this.list_check_box['sum_background'].enable = true
            }               
                            
            if (this.data.smc['nature_of_condition'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                                                                
                this.list_check_box['sum_nature_of_condition'].enable = true
            }                
            
            if (this.data.smc['sum_ece'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                   
                this.list_check_box['Summary_of_evidence_on_comparative_efficacy'].enable = true
            }
            // if (this.data.link['EssaisClinique']) {
            // this.list_check_box['detail_clinical_trials'].enable = true
            // }

            
            if (this.data.smc['sum_ecs'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                  
                this.list_check_box['sum_ecs'].enable = true
            }
            
            if (this.data.smc['sum_cei'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                                  
                this.list_check_box['sum_cei'].enable = true
            }                
            
            if (this.data.smc['sum_che'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                                                  
                this.list_check_box['sum_che'].enable = true
            }

            // this.list_check_box['detail_clinical_trials'].enable = true        

            if(this.data.smc['budget_impacts'].length > 0)
            {            
                this.list_check_box['detail_BudgetImpact'].enable = true
            }
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
            if(this.data.smc.evaluation_economic.length > 0) 
            { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }                
                            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false                    
            }                
            if ( this.data.smc['smc_effectiveness_evidence'] && this.data.smc['smc_effectiveness_evidence'].length > 0 )                
            {                  
                this.list_check_box['effectiveness_evidences'].enable = true
            }                 
                            
            if (this.data.smc['sum_odm'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                                                  
                this.list_check_box['sum_odm'].enable = true
            }                 
            
            if (this.data.smc['value_of_money'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                                                                  
                this.list_check_box['sum_value_of_money'].enable = true
            }

            if (this.data.smc['impact_beyond'].replace(/(<([^>]+)>)/gi, "").trim())  
            {                                                                                              
                this.list_check_box['sum_impact_beyond'].enable = true
            }       
                                
            if (this.data.smc['cost_nhs'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                                                                                                  
                this.list_check_box['sum_cost_nhs'].enable = true
            }
            
            if (this.data.smc['conclusion'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                                                                                                                  
                this.list_check_box['sum_conclusion'].enable = true
            }
            
            if (this.data.smc['sum_pce'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                                                                                                                                  
                this.list_check_box['sum_pce'].enable = true
            }
                    
            if (this.data.smc['sum_ppi'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                                                                                                                                                  
                this.list_check_box['sum_ppi'].enable = true
            }
            
            if (this.data.smc['add_gp'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                                                                                                                                                  
                this.list_check_box['add_gp'].enable = true
            }                 
            
            if (this.data.smc['add_c'].replace(/(<([^>]+)>)/gi, "").trim())  
            {  
                this.list_check_box['add_c'].enable = true
            }                
            
            if (this.data.smc['crc'].replace(/(<([^>]+)>)/gi, "").trim()) 
            {                                                                                                                                                                                                  
                this.list_check_box['crc'].enable = true
            }
        }
    },
    methods : {
       DDMMMYY,
       check_detail_conclusion,
       key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        FULL_BASE_URL(){
            return url_prismaccess
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },        			
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }   

    },

}
</script>

<style scoped>

a{
    color: #000;
}
/* b, strong {
    font-weight: bold;
} */

.box-toggle.icon-arrow-updown {
    font-size: 16px;
    right: -14px;
    color: #232426;
    margin-left:5px;
}
.box-toggle a:hover i {
    color: #ff6300;
}
.fa-angle-up {
    color : #ff6300;
}

th:last-child {
    border-right: 1px solid #e6e9ee;
}

table.comparateur2 tbody tr th {
    padding: 8px 3px;
    font-weight: normal;
}
table.comparateur2 > tbody > tr > td, table.comparateur2 > tbody > tr > th {
    border-bottom: 1px solid #e6e9ee;
    border-left: 1px solid #e6e9ee;
    border-collapse: collapse;
}
.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning.focus, .btn-warning:active, .btn-warning.active, .open > .dropdown-toggle.btn-warning {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512;
}
</style>